var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-form", { ref: "editForm" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBLBASEINFO" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editable && !_vm.isOld,
                              expression: "editable&&!isOld",
                            },
                          ],
                          attrs: {
                            label: "타 목표 불러오기",
                            icon: "save_alt",
                          },
                          on: { btnClicked: _vm.copyTarget },
                        }),
                        _vm.editable && _vm.isOld
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "delete" },
                              on: { btnClicked: _vm.deleteTarget },
                            })
                          : _vm._e(),
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                isSubmit: _vm.isSave,
                                url: _vm.saveUrl,
                                param: _vm.target,
                                mappingType: _vm.mappingType,
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveTarget,
                                btnCallback: _vm.saveTargetCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.target.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "plantCd", $$v)
                          },
                          expression: "target.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          required: true,
                          codeGroupCd: "MAIN_SAFETY_HEALTHY_TYPE_CD",
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "safetyHealthyTypeCd",
                          label: "구분",
                        },
                        model: {
                          value: _vm.target.safetyHealthyTypeCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "safetyHealthyTypeCd", $$v)
                          },
                          expression: "target.safetyHealthyTypeCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          default: "today",
                          label: "제목",
                          name: "targetTitle",
                        },
                        model: {
                          value: _vm.target.targetTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "targetTitle", $$v)
                          },
                          expression: "target.targetTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          type: "year",
                          default: "today",
                          label: "LBL0010014",
                          name: "targetYear",
                        },
                        model: {
                          value: _vm.target.targetYear,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "targetYear", $$v)
                          },
                          expression: "target.targetYear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-dept", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          type: "edit",
                          label: "LBLWRITEDEPT",
                          name: "writeDeptCd",
                        },
                        model: {
                          value: _vm.target.writeDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "writeDeptCd", $$v)
                          },
                          expression: "target.writeDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          required: true,
                          default: "today",
                          label: "LBLREGDATE",
                          name: "writeDt",
                        },
                        model: {
                          value: _vm.target.writeDt,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "writeDt", $$v)
                          },
                          expression: "target.writeDt",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "관계법령 의무사항 이행 계획/실적",
                  merge: _vm.grid.merge,
                  columns: _vm.grid.columns,
                  data: _vm.target.plans,
                  gridHeight: _vm.gridHeight,
                  editable: _vm.editable,
                  usePaging: false,
                  isExcelDown: true,
                  filtering: false,
                  isFullScreen: true,
                  columnSetting: false,
                  selection: "multiple",
                  rowKey: "mainSafetyHealthyTargetPlanId",
                },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "custom" && props.row.editFlag !== "C"
                          ? [
                              props.row.results && props.row.results.length > 0
                                ? [
                                    _c("q-btn", {
                                      staticStyle: { "font-weight": "700" },
                                      attrs: {
                                        dense: "",
                                        outline: "",
                                        color: "blue-9",
                                        label: `${
                                          props.row.results.length
                                        } ${_vm.$label("LBL0010049")}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openResult(props.row)
                                        },
                                      },
                                    }),
                                  ]
                                : [
                                    _c("q-btn", {
                                      staticStyle: { "font-weight": "700" },
                                      attrs: {
                                        dense: "",
                                        outline: "",
                                        color: "green-9",
                                        label: _vm.$label("LBL0010042"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openResult(props.row)
                                        },
                                      },
                                    }),
                                  ],
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLADD", icon: "add" },
                              on: { btnClicked: _vm.addTargetPlan },
                            })
                          : _vm._e(),
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLEXCEPT", icon: "remove" },
                              on: { btnClicked: _vm.deleteTargetPlan },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }